import cookie from 'react-cookies';
import axios from 'axios';

const cookieKeys = ['userId', 'teamId', 'token', 'id_token', 'mode', 'region',
  'majorVersionUpdate', 'historyOpenProject', 'code', 'scope', 'state', 'pluginTime', 'projectId'];
const storageKeys = ['historyOpenFile', 'avatar', 'permission_list', 'rootKey'];

const redirectToLogin = () => {
  if (Boolean(process.env.NODE_OPEN)) return;
  const openUrl = '/0/api/v1/user/open/url';
  axios.get(openUrl).then((res) => {
    const open = res.data.data;
    // const href = `//${open}/oauth2/auth?client_id=baihai-inner&scope=openid&response_type=code&state=${new Date().getTime()}&prompt=login&redirect_uri=${window.location.origin}/`;
    const href = `//${open}/login`
    window.location.href = href;
  }).catch((err) => {
    console.log(err);
  });
}

const logout = () => {
  const host = window.location.host;
  const hostname = window.location.hostname;
  const domain = host === 'localhost:3000' ? 'test.baihai.co' : host;
  const hostArr = hostname.split('.');
  const primayDomain = host === 'localhost:3000' ? 'baihai.co' : hostArr.slice(hostArr.length - 2).join('.');

  for (const key of cookieKeys) {
    cookie.remove(key, { path: "/", domain: primayDomain });
    cookie.remove(key, { path: "/", domain: domain });
    cookie.remove(key, { path: "/", domain: 'localhost' });
    cookie.remove(key, { path: "/", domain: hostname });
  }

  for (const key of storageKeys) {
    window.localStorage.removeItem(key);
  }

  redirectToLogin();
}

const isSuccess = (code) => {
  return (code >= 200 && code < 300) || (code >= 20000000 && code < 30000000);
}

export const checkAuth = (callback) => {
  const url = `/0/api/v1/user/checkAuth/?userId=${cookie.load('userId')}`;
  axios.get(url).then((res) => {
    console.log(res);
    if (isSuccess(res.data.code)) {
      callback && callback(res.data.data);
    } else {
      logout();
    }
  }).catch((err) => {
    console.log(err);
    logout();
  });
}
