/**
 * https://nightly-12.ilinksure.com/
 * https://nightly.ilinksure.com/
 * https://idp.baihai.co/
 * http://idp.ucloud.cn/
 * http://model.baihaiai.com/
 * http://idplm.baihai.co/
 * https://www.hnailab.com/
 * https://aios.wanjiedata.com/
 * http://idp.lider.cn/
 * http://60.31.21.42:12914
 * https://aiops.icloud.cn/
 * http://106.75.37.208:8003/
 * https://aiarea.icloud.cn/
 * https://aiops.icloud.cn/
 * https://ai.baihai.co/
 */

module.exports = {
    // 'REACT_APP_VERSION': 'SAAS',
    REACT_APP_VERSION: 'HOST',
    // REACT_APP_VERSION: 'MODEL',
    theme: 'default', // blue or default(black)
    originTargetUrl: 'http://gm.sw.com/',
    plugin: false, // true: 显示插件功能，false: 不显示插件功能, 但是若是用户为AIGC，plugin值失效，则不会显示插件市场功能
    port: 8099
}
