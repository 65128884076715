import { analysisUrl } from '../studio/config/auth';
const { port } = require('../config/global');
import axios from 'axios';
import cookie from 'react-cookies';

const saveCookie = (search) => {
  // 从url中获取登录信息
  const region = search.get('region');
  const teamId = search.get('teamId');
  const userId = search.get('userId');
  const idToken = search.get('idToken');
  const hostnameArr = window.location.hostname.split('.');
  const options = {
    path: '/',
    domain: hostnameArr.slice(hostnameArr.length - 2).join('.')
  };
  if (region && teamId && userId && idToken) {
    cookie.save('region', region, options);
    cookie.save('teamId', teamId, options);
    cookie.save('userId', userId, options);
    cookie.save('id_token', idToken, options);
    cookie.save('mode', 'private', options);
  }
}

async function loadAuthValue() {
    const search = new URLSearchParams(window.location.search);
    // saveCookie(search);
    const code = search.get("code");
    const scope = search.get("scope");
    const state = search.get("state");
    return await oryCallback(code, scope, state);
}

async function oryCallback(code, scope, state) {
    let result = new Promise((resolve, reject) => { resolve({ href: undefined, sourceEntry: 'auth2' }) });
    if (code && scope && state) {
        axios.defaults.headers['Content-Type'] = 'application/json; charset=utf-8';
        if (code && scope && state) {
            result = await axios.get(`/0/api/v1/user/ory/callback?code=${code}&scope=${scope}&state=${state}&redirect_uri=${window.location.origin}/`);
            if (process.env.NODE == 'pro' && result.data?.data?.redirectTo) {
                window.location.href = `//${result.data.data.redirectTo}/`
            } else {
                if (process.env.NODE == 'dev') {
                    window.location.href = `${window.location.origin}/`;
                }
            }
            result = new Promise((resolve, reject) => { resolve({ href: true, sourceEntry: 'login' }) });
        }
    }
    return result
}

export {
    oryCallback,
    loadAuthValue,
};