import { registerMicroApps, start, setDefaultMountApp } from 'qiankun';
import React from 'react';
import ReactDOM from 'react-dom';
import cookie from 'react-cookies';
import axios from 'axios';
import { loadAuthValue } from '../config/oryCallback';
import { checkAuth } from './auth';
import { setCookie } from './mock';

const studioPath = '/studio';
const loginPath = '/login';
const teamPath = '/team';
const sharePlatformPath = '/sharePlatform';
const registerPath = '/register';
const lcdpPath = '/lcdp';
const containerServicePath = '/containerService';
const imageHubPath = '/imageHub';
const galleryPath = '/gallery';
const enginePath = '/engine';
const terminalPath = '/terminal';
const workflowPath = '/workflow';
const consolePath = '/console';
const operationPath = '/operation';
const maintenancePath = '/maintenance';
const aimarketPath = '/aimarket';
const homePath = '/home';

const config = [
    {
        name: 'idp-home',
        entry: process.env.NODE == 'dev' ? '//localhost:9005' : '/child/idpStudio-home/',
        container: '#root',
        activeRule: homePath,
        enable: true
    },
    {
        name: 'idp-studio',
        entry: process.env.NODE == 'dev' ? '//localhost:8090' : '/child/idpStudio-idp/',
        container: '#root',
        activeRule: studioPath,
        enable: true
    },
    {
        name: 'idp-login',
        entry: process.env.NODE == 'dev' ? '//localhost:8091' : '/child/idpStudio-login/',
        container: '#root',
        activeRule: loginPath,
        enable: true
    },
    {
        name: 'idp-team',
        entry: process.env.NODE == 'dev' ? '//localhost:8092' : '/child/idpStudio-team/',
        container: '#root',
        activeRule: teamPath,
        enable: true
    },
    {
        name: 'idp-monitor',
        entry: process.env.NODE == 'dev' ? '//localhost:8093' : '/child/idpStudio-monitor/',
        container: '#root',
        activeRule: '/monitor',
        enable: true
    },
    {
        name: 'idp-shareplatform',
        entry: process.env.NODE == 'dev' ? '//localhost:8094' : '/child/idpStudio-sharePlatform/',
        container: '#root',
        activeRule: sharePlatformPath,
        enable: process.env.REACT_APP_VERSION === 'MODEL'
    },
    {
        name: 'idp-register',
        entry: process.env.NODE == 'dev' ? '//localhost:8095' : '/child/idpStudio-register/',
        container: '#root',
        activeRule: registerPath,
        enable: true
    },
    {
        name: 'idp-lcdp',
        entry: process.env.NODE == 'dev' ? '//localhost:8097' : '/child/idpStudio-lcdp/',
        container: '#root',
        activeRule: lcdpPath,
        enable: true
    },
    {
        name: 'idp-container-service',
        entry: process.env.NODE == 'dev' ? '//localhost:9000' : '/child/idpStudio-containerService/',
        container: '#root',
        activeRule: containerServicePath,
    },
    {
        name: 'idp-imageHub',
        entry: process.env.NODE == 'dev' ? '//localhost:8098' : '/child/idpStudio-imageHub/',
        container: '#root',
        activeRule: imageHubPath,
        enable: true
    },
    {
        name: 'idp-gallery',
        entry: process.env.NODE == 'dev' ? '//localhost:9002' : '/child/idpStudio-gallery/',
        container: '#root',
        activeRule: galleryPath,
        enable: true
    },
    {
        name: 'idp-aimarket',
        entry: process.env.NODE == 'dev' ? '//localhost:9004' : '/child/idpStudio-aimarket/',
        container: '#root',
        activeRule: aimarketPath,
        enable: true
    },
    {
        name: 'idp-terminal',
        entry: process.env.NODE == 'dev' ? '//localhost:9999' : '/child/idpStudio-terminal/',
        container: '#root',
        activeRule: terminalPath,
        enable: true
    },
    {
        name: 'idp-workflow',
        entry: process.env.NODE == 'dev' ? '//localhost:9091' : '/child/idpStudio-workflow/',
        container: '#root',
        activeRule: workflowPath,
        enable: true
    },
    {
        name: 'idp-console',
        entry: process.env.NODE == 'dev' ? '//localhost:8089' : '/child/idpStudio-console/',
        container: '#root',
        activeRule: consolePath,
        enable: true,
        loader: loading =>loading && console.log('loading', 'i am loading')
        // loader: loading =>loading  && (<div tip="Loading" style={{fontSize: '30px', fontWeight: '900', width: '500px', height: '500px', background: 'crimson'}}>
        //     {'loading.......'}
        // </div>)
    },
    {
        name: 'idp-operation',
        entry: process.env.NODE == 'dev' ? '//localhost:8088' : '/child/idpStudio-operation/',
        container: '#root',
        activeRule: operationPath,
        enable: true,
        // loader: loading =>loading  && (<div tip="Loading" style={{fontSize: '30px', fontWeight: '900', width: '500px', height: '500px', background: 'crimson', color: 'green'}}>
        //     {'loading.......'}
        // </div>)
    },
    {
        name: 'idp-maintenance',
        entry: process.env.NODE == 'dev' ? '//localhost:8087' : '/child/idpStudio-maintenance/',
        container: '#root',
        activeRule: maintenancePath,
        enable: true
    },
];

const defaultPath = (roleId) => {
    if (process.env.REACT_APP_VERSION == 'MODEL') {
        return sharePlatformPath;
    } else if (roleId) {
        if (roleId.indexOf(99) >= 0) {             // 超级管理员
            return maintenancePath;
        } else if (roleId.indexOf(98) >= 0) {     // 运维管理员
            return maintenancePath;
        } else if (roleId.indexOf(97) >= 0) {      // 运营管理员
            return operationPath;
        } else {                                   // 平台用户
            return consolePath;
        }
    } else {
        return consolePath;
    }
}

const skipAuth = () => {
    const skipPathname = [loginPath, registerPath, sharePlatformPath, galleryPath, aimarketPath, homePath, '/oauth2', '/child/idpStudio-login'];
    const currentPathname = window.location.pathname;
    let skip = false;
    for (const pathname of skipPathname) {
        if (currentPathname.startsWith(pathname)) {
            skip = true;
            break;
        }
    }
    return skip;
}

const checkPermission = (roleId) => {
    const currentPathname = window.location.pathname;
    if (roleId.includes(99)) {
        return;
    } else if (roleId.includes(98) && currentPathname.startsWith(maintenancePath)) {
        return;
    } else if (roleId.includes(97) && currentPathname.startsWith(operationPath)) {
        return;
    } else {
        if (currentPathname.startsWith(maintenancePath) || currentPathname.startsWith(operationPath)) {
            if (roleId.includes(98)) {
                window.location.href = maintenancePath;
            } else if (roleId.includes(97)) {
                window.location.href = operationPath;
            } else {
                window.location.href = consolePath;
            }
        }
    }
}

const markLastVisit = () => {
    const regionListUrl = `/2/api/v1/idp-product/resource/getRegionList?teamId=${cookie.load('teamId')}`;
    axios.get(regionListUrl).then((res) => {
        const origin = window.location.origin;
        const areaList = res.data.data;
        for (const area of areaList) {
            if (area.areaUrl === origin) {
                let appId = 0;
                const currentPathname = window.location.pathname;
                if (currentPathname.startsWith(studioPath) || currentPathname.startsWith(teamPath)) {
                    appId = 10100;
                } else if (currentPathname.startsWith(galleryPath)) {
                    appId = 10400;
                }
                if (appId != 0) {
                    const userModuleUrl = `/0/api/v1/gm/area/insert-user-module`;
                    axios.post(userModuleUrl, { moduleId: appId, areaId: area.id }).then(() => {

                    }).catch((err) => {
                        console.log(err);
                    });
                } else {
                    window.location.href = teamPath;
                }
                break;
            }
        }
    }).catch((err) => {
        console.log(err);
    });
}

const startApp = () => {
    registerMicroApps(config.filter(it => it.enable), {
        afterMount: [(_app) => {
            const loadDom = document.querySelector('#app-loading-gif');
            if (loadDom) {
                loadDom.style.display = 'none';
            }
        }]
    });
    // 启动 qiankun
    start({ prefetch: false });
}

// 自动设置cookie，本地调试用
if (process.env.NODE == "dev") {
    setCookie();
}

// 设置语言
cookie.save('locale', 'zhCN', { path: '/' });

if (window.location.pathname === '/' && !cookie.load('teamId')) {
    window.location.href = homePath;
}

loadAuthValue().then(res => {
    if (skipAuth()) {
        startApp();
    } else {
        checkAuth((data) => {
            startApp();
            const path = defaultPath(data.roleId);
            setDefaultMountApp(path);
            checkPermission(data.roleId);
            markLastVisit();
        });
    }
});

function render() {
    ReactDOM.render(<></>, document.querySelector('#root'));
}

render();
